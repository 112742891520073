import type { SeoProps } from 'src/types';

export const seo: SeoProps = {
  description:
    'Search job vacancies & get career advice with Jobstreet. Find jobs across Asia and get hired - all with Jobstreet!',
  title:
    "Jobstreet - Find your next job and get hired on Asia's favourite career site!",
  url: 'https://www.jobstreet.com/',
  robots: 'index,follow',
};
